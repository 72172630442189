import { Helmet } from "react-helmet";
import Header from "../../../components/header";
import BGImage from "../../../assets/images/pexels-pixabay-210726.jpg";

export default function PlanningAndDesign() {
  const headerContent = (
    <div className="flex flex-col h-full w-full justify-center">
      <h1 className="drop-shadow-lg text-4xl sm:text-6xl max-w-3xl font-bold text-white ">
        Planning And Design
      </h1>
      <p className="drop-shadow-lg text-white text-xl sm:text-2xl font-semibold mt-4 w-full">
        The <span className="italic">first step</span> in enhancing your
        building's operational intelligence.
      </p>
    </div>
  );

  return (
    <div className="flex flex-col justify-center w-full">
      <Helmet>
        <title>Planning & Design - Services | Applied BAS</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Check out our company to uncover how we strive to deliver building automation services with the latest technologies, within the Dallas Fort-Worth area."
        />
      </Helmet>
      <Header content={headerContent} image={BGImage} />
      <section className="mt-0 py-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto">
          <p className="text-primarydark">Services</p>
          <h1 className="text-4xl font-bold">Job Walk Estimates</h1>
          <p className="mt-4">
            This simple, although vital, step helps us understand the necessary
            improvements outside and inside the building. After walking and
            inspecting the facility, we will provide a scope of work with an
            approximate cost of our services and any equipment required.
          </p>
        </div>
        <div className="w-full max-w-[1250px] m-auto mt-12">
          <h1 className="text-4xl font-bold">Building Automation Design</h1>
          <p className="mt-4">
            OOur system design team will look for the best and most affordable
            technologies for the building based on its capability. For
            facilities with existing building automation, we may add, fix or
            upgrade these systems to improve energy efficiency, air quality,
            occupant comfort, and lower operating and maintenance costs.
          </p>
        </div>
        <div className="w-full max-w-[1250px] m-auto mt-12">
          <h1 className="text-4xl font-bold">
            Sequence of Operations / Program Planning
          </h1>
          <p className="mt-4">
            Sequence planning is a step-by-step process that our team develops
            to create a sequence of operations that will execute the systems
            integration portion of the project.
          </p>
        </div>
        <div className="w-full max-w-[1250px] m-auto mt-12">
          <h1 className="text-4xl font-bold ">
            Design Custom Graphical Interface
          </h1>
          <p className="mt-4">
            A team will inspect the current systems within the building for the
            new or upgraded equipment to be installed. This step consists of
            installing devices capable of receiving input, a computing system,
            and the manipulators that perform the work.
          </p>
        </div>
      </section>
    </div>
  );
}
