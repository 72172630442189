import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CLBackground from "../../../assets/images/Chris-Lewis_window-bkgrnd.jpg";
import FounderLayout from "../../../components/founderLayout";

export default function ChrisLewisAbout() {
  return (
    <div className="flex flex-col justify-center w-full">
      <Helmet>
        <title>Chris Lewis | About Us | Applied BAS</title>
        <meta charset="UTF-8" />
        <meta name="description" content="Check out our company to uncover how we strive to deliver building automation services with the latest technologies, within the Dallas Fort-Worth area." />
      </Helmet>
      <FounderLayout imgSrc={CLBackground} imgAlt="Chris Lewis" title="Owner / Operator" name="Chris Lewis" paragraphTop="Chris Lewis cofounded Applied Building Automation Services, LLC (Applied BAS) in 2016 with his business partner, Chris Gonzales. It wasnt until January 2020 that the company officially started taking on clients and pursuing projects. Both Mr. Lewis and Mr. Gonzales now serve as the managing partners, managing day-to-day operations, working with existing clients and winning new projects. Mr. Lewis had previously held several HVAC and BAS related positions with other companies in the Dallas-Fort Worth metroplex, where he and Chris Gonzales had the opportunity to meet and work together for the first time." paragraphMid="Mr. Lewis has 20 years of experience in the HVAC/Building Automation industry and has worked with clients such as the AT&T Stadium and the Dallas Convention Center, to realize complex control projects. Although Mr. Lewis is a Dallas-native, he spent his younger years in Oklahoma and earned a degree in Applied Science in HVAC technology from Oklahoma State University, graduating in 2007." paragraphBottom="Shortly after graduating in 2007, he moved back to the Dallas area where he met his wife, Jessica. After they married in 2008, they moved to Rowlett, TX to start a family. Currently, Mr. Lewis and his wife have 2 young and energetic boys, a mini schnauzer and a red-eared slider turtle." />
    </div>
  );
}
