import { Helmet } from "react-helmet";
import BGImage from "../../../assets/images/pexels-ga-huy-410696.jpg";
import Header from "../../../components/header";

export default function BuildingAutomationSupport() {
  // flex flex-col h-full w-full justify-center
  const headerContent = (
    <div className="flex flex-col justify-center w-full h-full">
      <h1 className="drop-shadow-lg text-4xl sm:text-6xl font-bold text-white ">
        Building Automation Support
      </h1>
      <p className="drop-shadow-lg text-white text-xl sm:text-2xl font-semibold mt-4 w-full">
        Discover how our clients maintain their buildings of tomorrow at a
        lesser cost.
      </p>
    </div>
  );

  return (
    <div className="flex flex-col justify-center w-full">
      <Helmet>
        <title>Building Automation Support - Services | Applied BAS</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Check out our company to uncover how we strive to deliver building automation services with the latest technologies, within the Dallas Fort-Worth area."
        />
      </Helmet>
      <Header content={headerContent} image={BGImage} />
      <section className="py-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto">
          <p className="text-primarydark">Support</p>
          <h1 className="text-4xl font-bold">Servicing</h1>
          <p className="mt-4">
            We help maintain or repair any BAS equipment already installed in a
            building. However, some devices might get old or broken over the
            years of use, so our team of technicians with vast experience will
            help remedy these issues.{" "}
          </p>
        </div>
        <div className="w-full max-w-[1250px] m-auto mt-12">
          <h1 className="text-4xl font-bold">Preventative Maintenance</h1>
          <p className="mt-4">
            Preventive Maintenance is an excellent service that helps keep BAS
            in a good state. Unfortunately, already-installed systems degrade
            over time, so our top priority is to keep our clients from spending
            more on unnecessary equipment and help maintain these systems to
            keep them in great shape.
          </p>
        </div>
        <div className="w-full max-w-[1250px] m-auto mt-12">
          <h1 className="text-4xl font-bold">On-site Client Training</h1>
          <p className="mt-4">
            To help our clients use our services, we provide the proper training
            to utilize our services and clarify building automation management.
            Most of our services are easy to handle and very fast to comprehend.
            It only takes a couple of sessions for our clients to understand the
            software solutions they will use.
          </p>
        </div>
        <div className="w-full max-w-[1250px] m-auto mt-12">
          <h1 className="text-4xl font-bold">Alarm Management</h1>
          <p className="mt-4">
            Thanks to our custom web-based and IoT solutions, we can handle all
            alarms and allow our clients to manage them fast. Our solutions
            include viewing past warnings in the system, taking immediate action
            if an alarm is serious, and silencing any inaccurate notices.
          </p>
        </div>
        <div className="w-full max-w-[1250px] m-auto mt-12">
          <h1 className="text-4xl font-bold">
            On-site and remote Tech Support
          </h1>
          <p className="mt-4">
            If it so happens that our clients need our support, we provide the
            help as required. However, if the issues happen to be an emergency,
            our team will promptly solve them, whether it involves remote or
            on-site troubleshooting.
          </p>
        </div>
      </section>
    </div>
  );
}
