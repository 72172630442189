import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CGBackground from "../../../assets/images/Chris-Gonz_window-bkgrnd.jpg";
import FounderLayout from "../../../components/founderLayout";

export default function ChrisGonzalesAbout() {
  return (
    <div className="flex flex-col justify-center w-full">
      <Helmet>
        <title>Chris Gonzales | About Us | Applied BAS</title>
        <meta charset="UTF-8" />
        <meta name="description" content="Check out our company to uncover how we strive to deliver building automation services with the latest technologies, within the Dallas Fort-Worth area." />
      </Helmet>
      <FounderLayout imgSrc={CGBackground} imgAlt="Chris Gonzales" title="Owner / Operator" name="Chris Gonzales"
        paragraphTop="Chris Gonzales co-founded Applied Building Automation Services (Applied BAS), LLC in 2016 with his business partner, Chris Lewis. It wasn&apos;t until January 2020 that the company officially started taking on clients and pursuing projects. Both Gonzales and Lewis now serve as the managing partners, managing day-to-day operations, working with existing clients and winning new projects."
        paragraphMid="Gonzales has over 20 years in the HVAC/Building Automation industry. As a Texas native, he attended ATI technical school for 11 months while he was employed with a mechanical HVAC contractor in the Dallas area. During his time with them, he was tasked with performing mechanical preventative maintenance and eventually was moved into the service department where he did mechanical HVAC service and new equipment start-up. This venture was a catalyst into several years of gained and invaluable experience as a technician working with mechanical HVAC contractors, including a solar energy company. His career eventually led into a management position during the last 5 years of his employment before joining forces with Chris Lewis. Gonzales&apos; attraction to the building automation industry was through his interest in computers and electronics. As he and Lewis discussed their shared interests and faith in God, Applied BAS was born."
        paragraphBottom="As Gonzales was getting started in his career, he married his long-time love, Terre, in January 2006. Through their years of marriage, they have added two daughters, a son and a young grandson into their lives. They currently reside in Aubrey, TX." />
    </div>
  );
}
