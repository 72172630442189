import "./App.css";
import "animate.css/animate.min.css";
import WhyUsImg from "./assets/images/building-professional.jpg";
import ResourceImg1 from "./assets/images/adeolu-eletu.jpg";
import ResourceImg2 from "./assets/images/mimi-thian.jpg";
import ResourceImg3 from "./assets/images/cherrydeck.jpg";
import ResourceImg4 from "./assets/images/nasa.jpg";
import CareersImg from "./assets/images/group.png";
import ServicesImg1 from "./assets/images/niagara.jpg";
import { Link } from "react-router-dom";
import Header from "./components/header";
import { Helmet } from "react-helmet";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import logoLight from "./assets/images/ABAS_logo_for_dark_backgrounds.png";
import BGVideo from './assets/videos/bg-video.mp4';
import BGVideoAlt from './assets/videos/bg-video.webm';

function App() {
  const ResourceCell = (resource, link, alt, title, content) => (
    <div className="flex flex-col">
      <img
        src={resource}
        alt={alt}
        className="rounded-lg h-[200px] object-cover"
      />
      <a href={link} className="text-lg font-bold mt-4 hover:underline text-primarydark">
        {title}
      </a>
      <p className="text-black">{content}</p>
    </div>
  );

  const HeaderContent = (
    <div className="flex flex-col h-full w-full justify-center">
      <img
        src={logoLight}
        className="py-4 hidden lg:block md:w-44 lg:w-52"
        alt="Applied BAS LLC Logo"
      />
      <h1 className="text-white font-bold text-4xl sm:text-6xl max-w-3xl drop-shadow-lg">
        Tomorrow's{" "}
        <span className="text-[#ffffff00] bg-clip-text bg-gradient-to-r from-[#00D2F4] to-highlight">
          Technologies
        </span>{" "}
        In Your{" "}
        <span className="text-[#ffffff00] bg-clip-text bg-gradient-to-r from-[#33CEFF] to-[#00D2F4]">
          Building
        </span>{" "}
        Today
      </h1>
      <div className="md:w-[50%]">
        <p className="text-white text-md sm:text-lg font-semibold mt-10 mb-8 drop-shadow-lg">
          Apply your own BAS (building automation system) with an automated and centralized control of your building's heating, lighting, ventilation, HVAC, and many other systems.
        </p>
      </div>
    </div>
  );

  const servicesLinkStyle =
    "text-lg font-bold underline underline-offset-4 hover:no-underline hover:text-primary text-primarydark";

  return (
    <div className="">
      <Helmet>
        <title>
          Applied BAS - Tomorrow's Technologies in your Building Today
        </title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Apply your own BAS (building automation system) with an automated and centralized control of your building's heating, lighting, ventilation, HVAC, and many other systems."
        />
        <meta name="keywords" content="Building Automation, Industrial Automation, DDC Controls, BAS System, Technology, Dallas Fort Worth, DFW, Niagara, JAYCE, Control Panel, BAS HVAC, HVAC" />
      </Helmet>
      <Header
        video={BGVideo}
        videoAlt={BGVideoAlt}
        content={HeaderContent}
      />
      {/* Advantages */}
      <section id="advantages" className="mt-20 mb-40 py-8 px-4 lg:px-8 w-full relative">
        <div className="w-full max-w-[1250px] m-auto grid grid-cols-1 lg:block gap-y-4 relative">
          <div className="w-full m-auto lg:m-0 lg:w-1/2 ">
            <h2 className="text-3xl font-bold text-center lg:text-left">
              Advantages of Building Automation
            </h2>
          </div>
          <div className="lg:absolute lg:top-0 lg:right-0 lg:w-1/2 lg:pl-8">
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
              <img
                alt="Set of people using technology with comfort"
                className="rounded-lg h-[400px] w-full object-cover"
                src={WhyUsImg}
              />
            </AnimationOnScroll>
          </div>
          <div className="mt-2 w-full lg:w-1/2">
            <p className="mb-4">
              Here at Applied BAS LLC, we implement building automation systems
              that will provide beneficial advantages such as:
            </p>
            <div className="grid grid-cols-2 mb-8">
              <ul className="mt-2">
                <li>Reduced energy costs</li>
                <div className="h-4"></div>
                <li>Increased productivity</li>
                <div className="h-4"></div>
                <li>Simplified Building Operation</li>
              </ul>
              <ul className="mt-2">
                <li>Reduced Maintenance</li>
                <div className="h-4"></div>
                <li>Indoor environment Improvement</li>
                <div className="h-4"></div>
                <li>Increased Property Value</li>
              </ul>
            </div>
            <div className="w-full text-center sm:text-left">
              <Link to="/about-us" className="btn">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Services */}
      <section className="my-20 lg:mt-12 py-8 px-4 lg:px-8 w-full lg:h-[500px]">
        <div className="w-full max-w-[1250px] m-auto relative">
          <div className="w-full lg:w-1/2 lg:absolute lg:right-0 lg:pl-8">
            <h2 className="text-3xl font-bold mb-2 text-center lg:text-left">
              What We Offer
            </h2>
          </div>
          <div className="w-full lg:w-1/2 lg:pr-8 lg:absolute lg:left-0 lg:top-0">
            <img
              alt=""
              src={ServicesImg1}
              className="rounded-lg h-[400px] w-full object-cover grayscale-[20%]"
            />
          </div>
          <div className="w-full lg:w-1/2 lg:absolute lg:right-0 lg:top-8 lg:pl-8">
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
              <div className="my-4">
                <Link to="services/planning-and-design" className={servicesLinkStyle}>
                  Planning & Design
                </Link>
                <p>
                  Job-Walk Estimates, Building Automation Design, Sequence of Operations/Program Planning, Design Custom Graphical Interface
                </p>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
              <div className="my-4">
                <Link to="services/building-automation-implementation" className={servicesLinkStyle}>
                  Building Automation Implementation
                </Link>
                <p>
                  Building Automation Installation, Controller Programming, System Startup and Commissioning
                </p>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
              <div className="my-4">
                <Link
                  to="services/integration-and-optimization"
                  className={servicesLinkStyle}
                >
                  Integration & Optimization
                </Link>
                <p>
                  Multi-vendor/legacy support, Web-based & IoT Solutions
                </p>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
              <div className="my-4">
                <Link to="services/building-automation-support" className={servicesLinkStyle}>
                  Building Automation Support
                </Link>
                <p>
                  Servicing, Preventative Maintenance, On-site Client Training, Alarm Management, On-site and remote Tech Support
                </p>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </section>
      {/* Resources */}
      <section className="mt-28 md:mt-20 pt-12 pb-20 px-4 lg:px-8 w-full bg-[#f0f0f0] text-black">
        <div className="w-full max-w-[1250px] m-auto">
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
            <h2 className="text-3xl font-bold text-center mb-16">
              Helpful Resources
            </h2>
          </AnimationOnScroll>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-8 md:gap-8">
            <AnimationOnScroll animateIn="animate__zoomIn" delay={50} animateOnce>
              {ResourceCell(
                ResourceImg1,
                "https://www.bcxa.org/blog/integrating-building-automation-systems.html",
                "Person testing System Integration on iPad",
                "How to integrate systems",
                "Learn more about your current system and how they can be adapted to new systems."
              )}
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn" delay={100} animateOnce>
              {ResourceCell(
                ResourceImg2,
                "https://www.facilitiesnet.com/buildingautomation/article/Understanding-How-Building-Controls-Can-Save-Energy--19490",
                "Happy Person Saving Energy",
                "Why is saving energy important",
                "See how building automation is beneficial for your building and the environment."
              )}
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn" delay={150} animateOnce>
              {ResourceCell(
                ResourceImg3,
                "https://clevair.io/blog/building-automation-guide/",
                "Team of people looking at laptop",
                "What you need to know about Buliding Automation",
                "Understand the perks and importance of building automation. "
              )}
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__zoomIn" delay={200} animateOnce>
              {ResourceCell(
                ResourceImg4,
                "https://spaceiq.com/blog/building-automation-products/",
                "Planet view from space with an energy grid",
                "Things in your building to keep in mind",
                "Checkout what things to lookout for when maintaining your buildings."
              )}
            </AnimationOnScroll>
          </div>
        </div>
      </section>
      {/* Careers */}
      <section className="min-h-[400px] my-20 py-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto grid grid-cols-1 lg:block gap-y-4 relative">
          <div className="">
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
              <h2 className="text-3xl font-bold text-center md:text-left">
                Bulid A Better Future With Us
              </h2>
            </AnimationOnScroll>
          </div>
          <div className="lg:absolute lg:top-0 lg:right-0 ml-4 lg:w-1/2 col-span-1 row-span-1 md:row-span-4">
            <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
              <img
                alt="Company group"
                className="rounded-lg"
                src={CareersImg}
                height={700}
              />
            </AnimationOnScroll>

          </div>
          <div className="lg:w-1/2 col-span-1 row-span-1 md:row-span-3 text-center sm:text-left">
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
              <p className="mb-4">
                Join our team of specialists as we expand our coverage of
                tomorrow’s technologies in buildings across the DFW metroplex.
              </p>
              <div className="w-full">
                <Link to="/careers" className="btn">
                  Explore Careers
                </Link>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </section>
      {/* Contact */}
      <div className="bg-[#FAFAFA] mt-16">
        <div className="px-4 py-8 flex flex-wrap flex-row justify-around max-w-[1350px] mx-auto">
          <h2 className="font-semibold text-2xl mb-4 text-center">
            Ready To Level Up Your Business?
          </h2>
          <Link to="/contact" className="btn">
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );
}

export default App;
