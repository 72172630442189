import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import JLBackground from "../../../assets/images/Jess-Lewis_window-bkgrnd.jpg";
import FounderLayout from "../../../components/founderLayout";

export default function JessicaLewisAbout() {
  return (
    <div className="flex flex-col justify-center w-full">
      <Helmet>
        <title>Jessica Lewis - About Us | Applied BAS</title>
        <meta charset="UTF-8" />
        <meta name="description" content="Check out our company to uncover how we strive to deliver building automation services with the latest technologies, within the Dallas Fort-Worth area." />
      </Helmet>
      <FounderLayout imgSrc={JLBackground} imgAlt="Jessical Lewis" title="Administrative Assistant" name="Jessica Lewis" paragraphTop="Jessica joined the Applied BAS team in November 2021 to work alongside her husband, Chris Lewis, and serve in the administrative segment of the rapidly growing company. She enjoys her role as the Administrative Manager within the company and every now and then she adds her creative skills into different aspects of the business." paragraphMid="Jessica was born and raised in the city of El Paso, Texas where she graduated with a Bachelor of Science in Civil Engineering degree from the University of Texas at El Paso (UTEP) in December 2006. Soon after graduation, she accepted a position at the Texas Department of Transportation (TxDOT) in 2007, where she earned an EIT (engineer-in-training) license as well as a PE (Professional Engineering) license in the state of Texas. During her 15 years at TxDOT, she moved her way up to the position of a Project Manager for a variety of high-profile construction projects within the Dallas District. Her project management experience along with her suitable organizational skills she acquired during her time at TxDOT, became an asset to her position with Applied BAS." paragraphBottom="While Jessica was working at TxDOT, she met Chris Lewis in September 2007 and married in September 2008. After they married, they moved to Rowlett, TX to start a family. Currently, Chris and Jessica have 2 young and energetic boys, a mini schnauzer and a red-eared slider turtle." />
    </div>
  );
}
