import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function FounderLayout(props) {
  return (
    <div className="flex flex-col justify-center w-full">
      <section className="mt-20 mb-20 py-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto">
          <div className="flex w-full flex-col lg:flex-row lg:grid-cols-2">
            <div className="mb-8 lg:mr-8">
              <AnimationOnScroll animateIn="animate__fadeInUp" duration={0.9} animateOnce>
                <img src={props.imgSrc} alt={props.imgAlt} className="w-[500px] h-[400px] sm:h-[500px] object-cover rounded-lg mx-auto" />
              </AnimationOnScroll>
            </div>
            <div className="w-full md:w-[60%] m-auto">
            <AnimationOnScroll animateIn="animate__fadeIn" delay={100} animateOnce>
              <h3 className="text-[#1F6779]">{props.title}</h3>
              <h1 className="text-3xl">{props.name}</h1>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn" delay={200} animateOnce>
              <p className="mt-4 text-justify">
                {props.paragraphTop}
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn" delay={200} animateOnce>
              <p className="mt-4 text-justify">
                {props.paragraphMid}
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn" delay={200} animateOnce>
              <p className="mt-4 text-justify">
                {props.paragraphBottom}
              </p>
            </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

